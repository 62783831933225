import axios from "axios";
import { productTypeMapping } from "utils";
const authkey = process.env.REACT_APP_OTP_AUTH_KEY;
const templateId = process.env.REACT_APP_TEMPLATE_ID;
const OTP_HOST = process.env.REACT_APP_OTP_HOST;
// const OTP_HOST = document.location.origin;
const STRAPI_HOST = process.env.REACT_APP_STRAPI_HOST;
const retrytype = "text";

export const URLS = {
  FEATURE_STORM: "/api/feature-storms?populate=*&sort[0]=id:asc",
  FEATURE_STORM_LR: "/api/feature-storm-lrs?populate=*&sort[0]=id:asc",

  FEATURE_HILOADS: "/api/feature-hiloads?populate=*&sort[0]=id:asc",
  STORM_EV_FEATURE: "/api/storm-ev-features?populate=*",
  STORM_EV_FEATURE_LR: "/api/storm-ev-feature-lrs?populate=*",

  STORM_FAQ: "/api/Faq-storms",
  STORM_FAQ_LR: "/api/Faq-storm-lrs",

  HILOAD_FAQ: "/api/Faq-3ws?populate=*",

  BOTTOM_CONTENT_HILOAD: "/api/Bottom-content-hiload?populate=*",
  BOTTOM_CONTENT_HOME: "/api/bottom-content-home?populate=*",

  DESIGN_CONTACT: "/api/Design-contact",
  DESIGN_CONTACT_3W: "/api/design-contact-3w",
  DESIGN_CONTACT_LR: "/api/design-contact-lr",

  STORM_EV_FEATURE: "/api/storm-ev-features?populate=*",
  STORM_EV_FEATURE_LR: "/api/storm-ev-feature-lrs?populate=*",

  STORM_FAQ: "/api/Faq-storms",
  STORM_FAQ_LR: "/api/Faq-storm-lrs",

  HILOAD_FAQ: "/api/Faq-3ws?populate=*",

  BOTTOM_CONTENT_HILOAD: "/api/Bottom-content-hiload?populate=*",

  DESIGN_CONTACT: "/api/Design-contact",
  DESIGN_CONTACT_3W: "/api/design-contact-3w",
  DESIGN_CONTACT_LR: "/api/design-contact-lr",

  Four_W_GALLERY: "/api/four-w-gallery?populate=*",
  Four_W_GALLERY_LR: "/api/four-w-gallery-lr?populate=*",
  THREE_W_Banner: "/api/slider-3ws?populate=*",
  THREE_W_FEATURE: "/api/three-w-features?populate=*",

  THREE_W_GALLERY: "/api/three-w-gallery?populate=*",

  THREE_W_Banner: "/api/slider-3ws?populate=*",
  THREE_W_FEATURE: "/api/three-w-features?populate=*",
  CONTACT_SECTION_TITLE: "/api/contact-section",
  PRODUCT_DATA: "/api/our-products?populate=*",
  SAVING_SECTION: "/api/saving-sections?populate=*",
  FEATURE_DEATAIL: "/api/feature-details?populate=*",
  BANK_OFFER: "/api/bank-offer?populate=*",
  CUSTOMER: "/api/customer-sections",
  FAQ: "/api/faqs",
  FOOTER: "/api/footer-assets?populate=*",
  FEATURES_LIST: "/api/feaure-lists",
  BANNERS: "/api/banners?populate=*",
  HILOADS: "/api/hiloads",
  TERMS: "/api/term-condition?populate=*",
  PRIVACY_POLICY: "/api/privacy-policy?populate=*",
  BROUCHER: "/api/brouchers?populate=*",
  ABOUT_US_BANNER: "/api/about?populate=*&locale=",
  ABOUT_US_FEATURES: "/api/about-us-features?populate=*&locale=",
  ABOUT_US_INFO: "/api/about-us-infos?locale=",
  VIDEO: "/api/videos?populate=*",
  VIDEO_ID: (id) => `/api/videos?filters[video_id][$eq]=${id}&populate=*`,
  HILOAD: "/api/hiload-infos?locale=",
  HILOAD_FEATURES: "/api/hiload-features?populate=*&locale=",
  HILOAD_BANNER_TEXT: "/api/hiload-banner-texts",
  IMAGE_SLIDERS: "/api/sliders?populate=*&locale=",
  IMAGE_SLIDERS_home: "/api/Slider-homes?populate=*",

  IMAGE_SLIDERS_4w: "/api/slider-4ws?populate=*",
  IMAGE_SLIDERS_4w_LR: "/api/slider-4w-lrs?populate=*",

  HILOAD_GALLERY: "/api/hiload-gallery?populate=*",
  CHARGING_NETWORKS: "/api/charging-networks?populate=*&locale=",
  CHARGING_NETWORKS_FEATURES:
    "/api/charging-network-features?populate=*&filters[id][$ne][1]=3&locale=",
  CHARGING_NETWORKS_SECTION:
    "/api/charging-network-sections?populate=*&locale=",
  FEATURES_SECTIONS: "/api/landing-features-hiloads?populate=*&locale=",
  COMPARISON: "/api/truck-details",
  TELEMATICS: "/api/telematics?populate=*&locale=",
  REFUND: "/api/refunds?populate=*",
  FOOTER_COLUMNS: "/api/footer-columns?populate=*&locale=",
  HILOAD_SLIDERS: "/api/slider-hiloads?populate=*",
  HOME_SLIDER: "/api/slider-landings?populate=*",
  PRODUCTS: "/api/hiload-vehicles?populate=*",
  VEHICALS: "/api/hiload-views?populate=*",
  TEAMS: "/api/about-us-teams?populate=*",
  CONTACT_US_BANNER: "/api/conatct-us-banners?populate=*",
  CONTACT_US_FEATURES: "/api/conatct-us-features?populate=*",
  CONTACT_US_SECTIONS: "/api/contact-us-sections?populate=*",
  CONTACT_US_INFO: "/api/contact-us-infos",
  CONTACT_US_FORM: "/api/conatct-us-forms",
  TEST_DRIVE_FORM: "/api/submission-test-drives",
  ENQUIRE_FORM: "/api/submission-enquiries",
  TEST_DRIVE_BANNER: "/api/test-drive-banners?populate=*",
  TEST_DRIVE_THANK_YOU: "/api/test-drive-thank-yous?populate=*",
  BOOKING_BANNER: "/api/booking-banners?populate=*",
  BOOKING_FORM: "/api/submission-bookings",
  BOOKING_THANK_YOU: "/api/submittion-booking-thankyous?populate=*",
  BOOKING_CHECKOUT: "/api/submittion-booking-checkouts?populate=*",
  HERO_SECTION: "/api/segment-first-section?populate=*&locale=",
  GLOBAL_CITIES: "/api/global-list-cities?populate=*",
  GLOBAL_STATES: "/api/states-lists?populate=*",
  BROUCHER_SUBMIT: "/api/submission-brochures",
  BUTTON_CTA: "/api/bottom-cta?populate=*&locale=",
  BOTTOM_CONTENT: "/api/bottom-content?populate=*&locale=",
  BOTTOM_CONTENT_LR: "/api/title?populate=*",

  LAUNCH_SLIDER: "/api/launch-slider?populate=*&sort[0]=id:asc",

  DEALERSHIP_BANNER: "/api/dealerships?populate=*",
  DEALERSHIP_INFO: "/api/dealership-infos",
  DEALERSHIP_SUBMIT: "/api/submission-dealerships",
  PROSPECTS: "/api/prospects",
  CHARGING_OPTION: "/api/charging-option?locale=",

  ABOUT_TEAM: "/api/about-team?locale=",

  SAVING_SECTION_TITLE: "/api/Saving-section-title",
  INSIGHT_TITLE: "/api/Insight-title",
  OUR_BLOG_TITLE: "/api/Our-blog-title",

  STORE_LOCATION:
    "/api/store-locations?populate=*&sort[0]=id:asc&pagination[pageSize]=200",

  // BLOGS: "/api/blogs?populate=*",
  BLOGS: (page, pageSize, locale) =>
    `/api/blogs?&populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}&&sort[0]=createdAt:desc&locale=${locale}`,
  our_blog: "/api/our-blogs?populate=*",

  BLOGS_CATEGORIES: "/api/blog-categories",
  ICONS: "/api/specification-icons?populate=*",
  SPECIFICATIONS: "/api/specifications",
  OUR_TEAM_TEXT: "/api/about-us-tab-texts?locale=",
  OUR_TEAM_HEADING: "/api/about-us-tab-headings?locale=",
  SUBSCRIBE: "/api/subscribes",
  SAVING_CALCULATOR: "/api/savings-calculator",
  EULER_AUDIO: "/api/euler-pronunciation?populate=*",

  VehicalFeature: "/api/vehical-feature?populate=*&locale=",

  NEWS: (page, year) =>
    `/api/news-articles?populate=*&pagination[page]=${page}&pagination[pageSize]=${10}&sort=published_date:desc`,

  FEATURED_NEWS:
    "/api/news-articles?populate=*&sort=published_date:desc&filters[featured_news_article][publishedAt][$notContainsi]=2002",
  PRODUCT_REVIEWS: (page, year) =>
    `/api/product-reviews?populate=*&pagination[page]=${page}&pagination[pageSize]=${5}&sort=published_date:desc`,
  GET_BLOG: (slug, locale) =>
    `/api/blogs?filters[blog_slug][$eq]=${slug}&populate=*&sort=published_date:desc&locale=${locale}`,
  BLOGS_AUTHORS: "/api/blog-authors",
  CAREERS_HERO: "/api/career-hero?populate=*",
  CAREERS_WHY: "/api/career-why",
  CAREERS_SECTION: "/api/career-sections?populate=*",
  TELEMATICS_HERO: "/api/telematics-hero?populate=*&locale=",
  TELEMATICS_INFO: "/api/telematics-info?populate=*&locale=",
  TELEMATICS_MULTISCREEN: "/api/telematics-multiscreen?populate=*&locale=",
  TELEMATICS_NOTIFICATIONS: "/api/telematics-notification?populate=*&locale=",
  INVESORS: "/api/euler-investors?populate=*",
  TESTIMONIALS: "/api/testimonials?populate=*&locale=",
  TESTIMONIALS_INFO: "/api/testimonial-info?locale=",
  TESTIMONIALS_LR: "/api/testimonials-lrs?populate=*",

  STORE_HERO: "/api/store-hero?populate=*",
  STORE_FEATURE: "/api/store-feature?populate=*",

  STORE_ADVANTAGE: "/api/store-advantage?populate=*",
  STORE_GALLERY: "/api/store-gallery?populate=*",

  SERVICE_HERO: "/api/service-hero?populate=*",
  SERVICE_Location: `/api/service-locations?populate=*&sort=id:desc`,

  SERVICE_OPTION: "/api/service-option?populate=*",
  SERVICE_GALLERY: "/api/service-gallery?populate=*",
  SERVICE_COMMITMENT: "/api/service-commitment?populate=*",

  LOCATIONS:
    "/api/charging-network-hubs?populate[0]=city_located&populate[1]=city_located.linked_state?populate=*",
  OTP_SEND: (phone) =>
    `${OTP_HOST}/otp?template_id=${templateId}&otp_expiry=1&mobile=${phone}&authkey=${authkey}`,
  OTP_VERIFY: (otp, phone) =>
    `${OTP_HOST}/otp/verify?otp=${otp}&authkey=${authkey}&mobile=91${phone}`,
  OTP_RESEND: (phone) =>
    `${OTP_HOST}/otp/retry?authkey=${authkey}&retrytype=${retrytype}&mobile=${phone}`,

  DEALER_ONBOARD: "/api/dealer-onboard",
  BOOK_VEHICLE: "/api/book-vehicle",
  BOOK_PILOT: "/api/book-pilot",
  ENQUIRY_LEAD: "/api/lead-enquiry"
};

export const HOST = STRAPI_HOST;
export const HOST_STG = "http://3.7.68.60:1337";
export const TOKEN = "";
export const TOKEN_STG = process.env.REACT_APP_TOKEN;

const submitDealerOnBoard = async (params) => {
  try {
    const { data } = await axios.post(
      OTP_HOST + URLS.DEALER_ONBOARD,
      {
        dealerDataObj: params,
      }
    );
    return data;
  } catch (err) {
    throw err;
  }
};

const submitBookVehicle = async (params) => {
  try {
    const { data } = await axios.post(OTP_HOST + URLS.BOOK_VEHICLE, {
      dataWrapperObj: params,
    });
    return data;
  } catch (err) {
    throw err;
  }
};

const submitBookPilot = async (params) => {
  const wrapperObj = {
    firstName: params?.first_name,
    lastName: params?.last_name,
    email: params?.email_address,
    phone: params?.phone_number,
    financeRequired: true,
    state: params?.state,
    city: params?.city,
    pincode: params?.pincode,
    usageState: params?.usage_state,
    usageCity: params?.usage_city,
    planningToPurchase: "Within 7 days",
    ownAnyVehicle: true,
    communicationConsent: true,
    productType: productTypeMapping[params?.product_name],
}
  try {
    const { data } = await axios.post(OTP_HOST + URLS.BOOK_PILOT, {
      dataWrapperObj: wrapperObj,
    });

    var strapiData;
    if(data?.message?.status === 'success') {
      strapiData = await submitTestDrive(params);
    }

    return strapiData;
  } catch (err) {
    throw err;
  }
};

const getOurProduct = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.PRODUCT_DATA);
    return data;
  } catch (err) {
    throw err;
  }
};
const getCustomerData = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.CUSTOMER);
    return data;
  } catch (err) {
    throw err;
  }
};

const getFeatureDetails = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.FEATURE_DEATAIL);
    return data;
  } catch (err) {
    throw err;
  }
};

const getStormFaq = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.STORM_FAQ);
    return data;
  } catch (err) {
    throw err;
  }
};
const getStormFaqLr = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.STORM_FAQ_LR);
    return data;
  } catch (err) {
    throw err;
  }
};

const getHiloadFaq = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.HILOAD_FAQ);
    return data;
  } catch (err) {
    throw err;
  }
};

const getServiceHero = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.SERVICE_HERO);
    return data;
  } catch (err) {
    throw err;
  }
};
const getFourWGallery = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.Four_W_GALLERY);
    return data;
  } catch (err) {
    throw err;
  }
};
const getFourWGalleryLr = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.Four_W_GALLERY_LR);
    return data;
  } catch (err) {
    throw err;
  }
};

const getThreeWGallery = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.THREE_W_GALLERY);
    return data;
  } catch (err) {
    throw err;
  }
};
const getFeatureStorm = async (hiload) => {
  let endPoint = hiload ? URLS.FEATURE_HILOADS : URLS.FEATURE_STORM;
  try {
    const { data } = await axios.get(HOST + endPoint);
    return data;
  } catch (err) {
    throw err;
  }
};
const getFeatureStormLr = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.FEATURE_STORM_LR);
    return data;
  } catch (err) {
    throw err;
  }
};

const getSavingSection = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.SAVING_SECTION);
    return data;
  } catch (err) {
    throw err;
  }
};
const getSavingSectionTitle = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.SAVING_SECTION_TITLE);
    return data;
  } catch (err) {
    throw err;
  }
};

const getServiceLocation = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.SERVICE_Location);
    return data;
  } catch (err) {
    throw err;
  }
};

const getBankOffer = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.BANK_OFFER);
    return data;
  } catch (err) {
    throw err;
  }
};

const getBlogTitle = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.OUR_BLOG_TITLE);
    return data;
  } catch (err) {
    throw err;
  }
};

const get3WBanner = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.THREE_W_Banner);
    return data;
  } catch (err) {
    throw err;
  }
};
const getContactSectionTitle = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.CONTACT_SECTION_TITLE);
    return data;
  } catch (err) {
    throw err;
  }
};
const get3WFeature = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.THREE_W_FEATURE);
    return data;
  } catch (err) {
    throw err;
  }
};
const getServiceOption = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.SERVICE_OPTION);
    return data;
  } catch (err) {
    throw err;
  }
};
const getBottomContentHiload = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.BOTTOM_CONTENT_HILOAD);
    return data;
  } catch (err) {
    throw err;
  }
};

const getBottomContentHome = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.BOTTOM_CONTENT_HOME);
    return data;
  } catch (err) {
    throw err;
  }
};
//
const getHiloadGallery = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.HILOAD_GALLERY);
    return data;
  } catch (err) {
    throw err;
  }
};
const getServiceGallery = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.SERVICE_GALLERY);
    return data;
  } catch (err) {
    throw err;
  }
};

const getAboutTeam = async (lng) => {
  try {
    const { data } = await axios.get(HOST + URLS.ABOUT_TEAM + lng);
    return data;
  } catch (err) {
    throw err;
  }
};

const getServiceCommitment = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.SERVICE_COMMITMENT);
    return data;
  } catch (err) {
    throw err;
  }
};

const getStoreHero = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.STORE_HERO);
    return data;
  } catch (err) {
    throw err;
  }
};

const getStoreFeature = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.STORE_FEATURE);
    return data;
  } catch (err) {
    throw err;
  }
};

const getStoreLocation = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.STORE_LOCATION);
    return data;
  } catch (err) {
    throw err;
  }
};

const getStoreAdvantage = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.STORE_ADVANTAGE);
    return data;
  } catch (err) {
    throw err;
  }
};

const getStoreGallery = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.STORE_GALLERY);
    return data;
  } catch (err) {
    throw err;
  }
};

const getFaqs = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.FAQ);
    return data;
  } catch (err) {
    throw err;
  }
};

const getTestimonials = async (lng) => {
  try {
    const { data } = await axios.get(HOST + URLS.TESTIMONIALS + lng);
    return data;
  } catch (err) {
    throw err;
  }
};

const getBottomContent = async (lng) => {
  try {
    const { data } = await axios.get(HOST + URLS.BOTTOM_CONTENT + lng);
    return data;
  } catch (err) {
    throw err;
  }
};
const getBottomContentLR = async (lng) => {
  try {
    const { data } = await axios.get(HOST + URLS.BOTTOM_CONTENT_LR);
    return data;
  } catch (err) {
    throw err;
  }
};

const getBookaTestRideData = async (lng) => {
  try {
    const { data } = await axios.get(HOST + URLS.BUTTON_CTA + lng);
    return data;
  } catch (err) {
    throw err;
  }
};

const getTestimonialsInfo = async (lng) => {
  try {
    const { data } = await axios.get(HOST + URLS.TESTIMONIALS_INFO + lng);
    return data;
  } catch (err) {
    throw err;
  }
};
const getTestimonialsLR = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.TESTIMONIALS_LR);
    return data;
  } catch (err) {
    throw err;
  }
};

const getChargingOption = async (lng) => {
  try {
    const { data } = await axios.get(HOST + URLS.CHARGING_OPTION + lng);
    return data;
  } catch (err) {
    throw err;
  }
};

const getPrivacyPolicy = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.PRIVACY_POLICY);
    return data;
  } catch (err) {
    throw err;
  }
};

const getSpecifications = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.SPECIFICATIONS);
    return data;
  } catch (err) {
    throw err;
  }
};

const getVehicalFeature = async (lng) => {
  try {
    const { data } = await axios.get(HOST + URLS.VehicalFeature + lng);
    return data;
  } catch (err) {
    throw err;
  }
};

const getInvestors = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.INVESORS);
    return data;
  } catch (err) {
    throw err;
  }
};

const getCarrerHero = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.CAREERS_HERO);
    return data;
  } catch (err) {
    throw err;
  }
};

const getTemematicsHero = async (lng) => {
  try {
    const { data } = await axios.get(HOST + URLS.TELEMATICS_HERO + lng);
    return data;
  } catch (err) {
    throw err;
  }
};

const getTemematicsInfo = async (lng) => {
  try {
    const { data } = await axios.get(HOST + URLS.TELEMATICS_INFO + lng);
    return data;
  } catch (err) {
    throw err;
  }
};

const getTemematicsMultiscreens = async (lng) => {
  try {
    const { data } = await axios.get(HOST + URLS.TELEMATICS_MULTISCREEN + lng);
    return data;
  } catch (err) {
    throw err;
  }
};

const getTemematicsNotifications = async (lng) => {
  try {
    const { data } = await axios.get(
      HOST + URLS.TELEMATICS_NOTIFICATIONS + lng
    );
    return data;
  } catch (err) {
    throw err;
  }
};

const getCarrerSections = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.CAREERS_SECTION);
    return data;
  } catch (err) {
    throw err;
  }
};

const getCarrerWhy = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.CAREERS_WHY);
    return data;
  } catch (err) {
    throw err;
  }
};

const getProductReviews = async (page = 1) => {
  try {
    const data = await axios.get(HOST + URLS.PRODUCT_REVIEWS(page));
    return data;
  } catch (err) {
    throw err;
  }
};

const getNews = async (page = 1, year = "2024") => {
  try {
    const data = await axios.get(HOST + URLS.NEWS(page, year));
    return data;
  } catch (err) {
    throw err;
  }
};

const getInsightTitle = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.INSIGHT_TITLE);
    return data;
  } catch (err) {
    throw err;
  }
};
const getFeaturedNews = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.FEATURED_NEWS);
    return data;
  } catch (err) {
    throw err;
  }
};

const subscribeUser = async (email) => {
  try {
    const { data } = await axios.post(HOST + URLS.SUBSCRIBE, {
      data: { email },
    });
    return data;
  } catch (err) {
    throw err;
  }
};

const getAudio = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.EULER_AUDIO);
    return data;
  } catch (err) {
    throw err;
  }
};
const getSavingsCalculator = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.SAVING_CALCULATOR);
    return data;
  } catch (err) {
    throw err;
  }
};

const getAboutUsTabHeading = async (lng) => {
  try {
    const { data } = await axios.get(HOST + URLS.OUR_TEAM_HEADING + lng);
    return data;
  } catch (err) {
    throw err;
  }
};

const getAboutUsTabText = async (lng) => {
  try {
    const { data } = await axios.get(HOST + URLS.OUR_TEAM_TEXT + lng);
    return data;
  } catch (err) {
    throw err;
  }
};

const getIcons = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.ICONS);
    return data;
  } catch (err) {
    throw err;
  }
};

const getBlogs = async (page = 1, pageSize, locale) => {
  try {
    const { data } = await axios.get(HOST + URLS.BLOGS(page, pageSize, locale));
    return data;
  } catch (err) {
    throw err;
  }
};

const getOurPage = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.our_blog);
    return data;
  } catch (err) {
    throw err;
  }
};
const getBlogsAuthors = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.BLOGS_AUTHORS);
    return data;
  } catch (err) {
    throw err;
  }
};

const getBlogsCategories = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.BLOGS_CATEGORIES);
    return data;
  } catch (err) {
    throw err;
  }
};

const getBlogBySlug = async (slug, locale) => {
  try {
    const { data } = await axios.get(HOST + URLS.GET_BLOG(slug, locale));
    return data;
  } catch (err) {
    throw err;
  }
};

const getDealershipBanner = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.DEALERSHIP_BANNER);
    return data;
  } catch (err) {
    throw err;
  }
};

const getDealershipInfo = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.DEALERSHIP_INFO);
    return data;
  } catch (err) {
    throw err;
  }
};

const getAboutUsInfo = async (lng) => {
  try {
    const { data } = await axios.get(HOST + URLS.ABOUT_US_INFO + lng);
    return data;
  } catch (err) {
    throw err;
  }
};

const getChargingNetworkCities = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.GLOBAL_CITIES);
    return data;
  } catch (err) {
    throw err;
  }
};

const getAboutUsFeatures = async (lng) => {
  try {
    const { data } = await axios.get(HOST + URLS.ABOUT_US_FEATURES + lng);
    return data;
  } catch (err) {
    throw err;
  }
};

const getAboutUsBanner = async (lng) => {
  try {
    const { data } = await axios.get(HOST + URLS.ABOUT_US_BANNER + lng);
    return data;
  } catch (err) {
    throw err;
  }
};

const getBookingThankyou = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.BOOKING_THANK_YOU);
    return data;
  } catch (err) {
    throw err;
  }
};

const getBookingCheckout = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.BOOKING_CHECKOUT);
    return data;
  } catch (err) {
    throw err;
  }
};

const getLocations = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.LOCATIONS);
    return data;
  } catch (err) {
    throw err;
  }
};

const getChargingNetworkStates = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.GLOBAL_STATES);
    return data;
  } catch (err) {
    throw err;
  }
};

const getOtp = async (num) => {
  try {
    const { data } = await axios.get(`${OTP_HOST}/api/otp?phone=${num}`, {
      headers: {
        Authorization: "",
        ContentType: "application/json",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
      },
    });
    return data;
  } catch (err) {
    throw err;
  }
};

const resendOtp = async (phone) => {
  try {
    const { data } = await axios.get(`${OTP_HOST}/api/resend?phone=${phone}`);
    return data;
  } catch (err) {
    throw err;
  }
};

const submitTestDrive = async (params) => {
  try {
    const { data } = await axios.post(HOST + URLS.TEST_DRIVE_FORM, {
      data: params,
    });
    return data;
  } catch (err) {
    throw err;
  }
};

const submitBookingForm = async (params) => {
  try {
    const { data } = await axios.post(HOST + URLS.BOOKING_FORM, {
      data: params,
    });
    return data;
  } catch (err) {
    throw err;
  }
};

const submitProspects = async (params) => {
  try {
    const { data } = await axios.post(HOST + URLS.PROSPECTS, {
      data: params,
    });
    return data;
  } catch (err) {
    throw err;
  }
};

const sendBrouchure = async (params) => {
  const wrapperObj = {
    name:params?.first_name,
    email:  "no-reply@eulermotors.com",
    phone: params?.phone_number,
    city: params?.city,
    description: "Brochure-Download",
    productType: params?.product ? productTypeMapping[params?.product] : "3WC",
  }

  try {
    const { data } = await axios.post(OTP_HOST + URLS.ENQUIRY_LEAD, {
      dataWrapperObj: wrapperObj,
    });

    var strapiData;
    if(data?.message?.status === "success") {
      strapiData = await submitBroucher(params);
    }
    return strapiData;
  } catch(err) {
    throw err;
  }
}

const submitBroucher = async (params) => {
  try {
    const { data } = await axios.post(HOST + URLS.BROUCHER_SUBMIT, {
      data: params,
    });
    return data;
  } catch (err) {
    throw err;
  }
};

const submitDealership = async (params) => {
  try {
    const { data } = await axios.post(HOST + URLS.DEALERSHIP_SUBMIT, {
      data: params,
    });
    return data;
  } catch (err) {
    throw err;
  }
};

const verifyOtp = async (phone, otp) => {
  try {
    const { data } = await axios.get(
      `${OTP_HOST}/api/verify?phone=${phone}&otp=${otp}`,
      {
        headers: {
          Authorization: "",
          "content-type": "application/json",
        },
      }
    );
    return data;
  } catch (err) {
    throw err;
  }
};

const getHeroSectionBanner = async (lng) => {
  try {
    const { data } = await axios.get(HOST + URLS.HERO_SECTION + lng);
    return data;
  } catch (err) {
    throw err;
  }
};

const submitContactForm = async (params) => {
  try {
    const { data } = await axios.post(HOST + URLS.CONTACT_US_FORM, {
      data: params,
    });
    return data;
  } catch (err) {
    throw err;
  }
};

const sendEnquireFormData = async (params) => {
  const enquireWrapperObj = {
    name:params?.first_name,
    email: "noreply@eulermotors.com",
    phone: params?.phone_number,
    city: params?.city,
    productType: params?.productType,
    description:params?.description
  }

  try {
    const { data } = await axios.post(OTP_HOST + URLS.ENQUIRY_LEAD, {
      dataWrapperObj: enquireWrapperObj,
    });

    var strapiData;
    if(data?.message?.status === "success") {
      strapiData = await submitEnquireForm(params);
    }
    return strapiData;
  } catch(err) {
    throw err;
  }
}

const submitEnquireForm = async (params) => {
  try {
    const { data } = await axios.post(HOST + URLS.ENQUIRE_FORM, {
      data: params,
    });
    return data;
  } catch (err) {
    throw err;
  }
};

const getTestDriveBanner = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.TEST_DRIVE_BANNER);
    return data;
  } catch (err) {
    throw err;
  }
};

const getBookingBanner = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.BOOKING_BANNER);
    return data;
  } catch (err) {
    throw err;
  }
};

const getTestDriveThankYou = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.TEST_DRIVE_THANK_YOU);
    return data;
  } catch (err) {
    throw err;
  }
};

const getContactUsBanner = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.CONTACT_US_BANNER);
    return data;
  } catch (err) {
    throw err;
  }
};
const getContactUsFeatures = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.CONTACT_US_FEATURES);
    return data;
  } catch (err) {
    throw err;
  }
};

const getContactUsSections = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.CONTACT_US_SECTIONS);
    return data;
  } catch (err) {
    throw err;
  }
};

const getContactUsInfo = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.CONTACT_US_INFO);
    return data;
  } catch (err) {
    throw err;
  }
};

const getTeams = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.TEAMS);
    return data;
  } catch (err) {
    throw err;
  }
};

const getVehicals = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.VEHICALS);
    return data;
  } catch (err) {
    throw err;
  }
};

const getHiiloadSliders = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.HILOAD_SLIDERS);
    return data;
  } catch (err) {
    throw err;
  }
};

const getProducts = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.PRODUCTS);
    return data;
  } catch (err) {
    throw err;
  }
};

const getFooterColumns = async (lng) => {
  try {
    const { data } = await axios.get(HOST + URLS.FOOTER_COLUMNS + lng);
    return data;
  } catch (err) {
    throw err;
  }
};

const getRefund = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.REFUND);
    return data;
  } catch (err) {
    throw err;
  }
};

const getTelemetics = async (lng) => {
  try {
    const { data } = await axios.get(HOST + URLS.TELEMATICS + lng);
    return data;
  } catch (err) {
    throw err;
  }
};

const getComparison = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.COMPARISON);
    return data;
  } catch (err) {
    throw err;
  }
};

const getFeatureImages = async (lng) => {
  try {
    const { data } = await axios.get(HOST + URLS.FEATURES_SECTIONS + lng);
    return data;
  } catch (err) {
    throw err;
  }
};

const getChargingNetworkSections = async (lng) => {
  try {
    const { data } = await axios.get(
      HOST + URLS.CHARGING_NETWORKS_SECTION + lng
    );
    return data;
  } catch (err) {
    throw err;
  }
};
const getChargingNetworkFeatures = async (lng) => {
  try {
    const { data } = await axios.get(
      HOST + URLS.CHARGING_NETWORKS_FEATURES + lng
    );
    return data;
  } catch (err) {
    throw err;
  }
};

const getCharginNetworkSubHeading = async (lng) => {
  try {
    const { data } = await axios.get(HOST + URLS.CHARGING_NETWORKS + lng);
    return data;
  } catch (err) {
    throw err;
  }
};

const getImageSlider = async (lng) => {
  try {
    const { data } = await axios.get(HOST + URLS.IMAGE_SLIDERS + lng);
    return data;
  } catch (err) {
    throw err;
  }
};
const getImageSliderHome = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.IMAGE_SLIDERS_home);
    return data;
  } catch (err) {
    throw err;
  }
};

const getImageSliderFourW = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.IMAGE_SLIDERS_4w);
    return data;
  } catch (err) {
    throw err;
  }
};
const getImageSliderFourWLR = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.IMAGE_SLIDERS_4w_LR);
    return data;
  } catch (err) {
    throw err;
  }
};

const getHiloadFeatures = async (lng) => {
  try {
    const { data } = await axios.get(HOST + URLS.HILOAD_FEATURES + lng);
    return data;
  } catch (err) {
    throw err;
  }
};

const getStormEvFeatures = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.STORM_EV_FEATURE);
    return data;
  } catch (err) {
    throw err;
  }
};
const getStormEvFeaturesLR = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.STORM_EV_FEATURE_LR);
    return data;
  } catch (err) {
    throw err;
  }
};
const getHiloadBannerText = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.HILOAD_BANNER_TEXT);
    return data;
  } catch (err) {
    throw err;
  }
};

const getHiloadInfo = async (lng) => {
  try {
    const { data } = await axios.get(HOST + URLS.HILOAD + lng);
    return data;
  } catch (err) {
    throw err;
  }
};

const getVideo = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.VIDEO);
    return data;
  } catch (err) {
    throw err;
  }
};

const getVideoById = async (id) => {
  try {
    const { data } = await axios.get(HOST + URLS.VIDEO_ID(id));
    return data;
  } catch (err) {
    throw err;
  }
};
const getDesignContact = async (hiload) => {
  const url = hiload ? URLS.DESIGN_CONTACT_3W : URLS.DESIGN_CONTACT;
  try {
    const { data } = await axios.get(HOST + url);
    return data;
  } catch (err) {
    throw err;
  }
};
const getDesignContactLR = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.DESIGN_CONTACT_LR);
    return data;
  } catch (err) {
    throw err;
  }
};
const getTermsOfUse = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.TERMS);
    return data;
  } catch (err) {
    throw err;
  }
};

const getBroucher = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.BROUCHER);
    return data;
  } catch (err) {
    throw err;
  }
};

const getBanners = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.BANNERS);
    return data;
  } catch (err) {
    throw err;
  }
};

const getHomeSlider = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.HOME_SLIDER);
    return data;
  } catch (err) {
    throw err;
  }
};

const getLaunchSider = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.LAUNCH_SLIDER);
    return data;
  } catch (err) {
    throw err;
  }
};

const getFeaturesList = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.FEATURES_LIST);
    return data;
  } catch (err) {
    throw err;
  }
};

// const getAssets = async () => {
//   try {
//     const { data } = await axios.get(HOST + URLS.ASSETS);
//     return data;
//   } catch (err) {
//     throw err;
//   }
// };

const getHiloads = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.HILOADS);
    return data;
  } catch (err) {
    throw err;
  }
};

const getFooterAssets = async () => {
  try {
    const { data } = await axios.get(HOST + URLS.FOOTER);
    return data;
  } catch (err) {
    throw err;
  }
};

const optGet = async (phone) => {
  try {
    const { data } = await axios.get(`/api/otp?phone=${phone}`);
    return data;
  } catch (err) {
    throw err;
  }
};

export const apiClient = {
  getFaqs,
  // getAssets,
  getFooterAssets,
  getVehicalFeature,
  getFeaturesList,
  getBanners,
  getHiloads,
  getTermsOfUse,
  getBottomContentHiload,
  getBroucher,
  getVideo,
  getHiloadInfo,
  getHiloadFeatures,
  getHiloadBannerText,
  getImageSlider,
  getCharginNetworkSubHeading,
  getStormFaq,
  getChargingNetworkFeatures,
  getHiloadFaq,
  getChargingNetworkSections,
  getFeatureImages,
  getComparison,
  getTelemetics,
  getFooterColumns,
  getRefund,
  getHiiloadSliders,
  getHomeSlider,
  getProducts,
  getTeams,
  getVehicals,
  getContactUsBanner,
  getContactUsFeatures,
  getContactUsInfo,
  getSavingSectionTitle,
  getContactUsSections,
  submitContactForm,
  getTestDriveBanner,
  getTestDriveThankYou,
  getBookingBanner,
  getOurPage,
  submitEnquireForm,
  getHeroSectionBanner,
  resendOtp,
  getOtp,
  verifyOtp,
  submitTestDrive,
  getChargingNetworkCities,
  getChargingNetworkStates,
  getLocations,
  submitBroucher,
  getInsightTitle,
  optGet,
  submitBookingForm,
  getAboutTeam,
  getBookingThankyou,
  getBookingCheckout,
  getAboutUsBanner,
  getAboutUsFeatures,
  getAboutUsInfo,
  getDealershipBanner,
  getDealershipInfo,
  submitDealership,
  getBlogs,
  getServiceHero,
  getServiceCommitment,
  getBottomContentLR,
  getServiceGallery,
  getBlogTitle,
  getStoreAdvantage,
  getStoreGallery,
  getStoreHero,
  getDesignContact,
  getStoreFeature,
  getStoreLocation,
  getBlogsAuthors,
  getServiceLocation,
  getBlogsCategories,
  getBlogBySlug,
  getSavingSection,
  getFeatureStormLr,
  getImageSliderFourWLR,
  getIcons,
  getAboutUsTabHeading,
  getAboutUsTabText,
  getSavingsCalculator,
  subscribeUser,
  getFourWGalleryLr,
  getAudio,
  getTestimonialsLR,
  get3WFeature,
  getNews,
  getFeatureDetails,
  getThreeWGallery,
  getFeaturedNews,
  getDesignContactLR,
  getLaunchSider,
  getContactSectionTitle,
  getStormFaqLr,
  getProductReviews,
  getCarrerHero,
  getImageSliderHome,
  getCarrerSections,
  getCarrerWhy,
  getFeatureStorm,
  getFourWGallery,
  getStormEvFeaturesLR,
  getSpecifications,
  getTemematicsHero,
  getChargingOption,
  getHiloadGallery,
  submitProspects,
  get3WBanner,
  getTemematicsInfo,
  getServiceOption,
  getTemematicsMultiscreens,
  getTemematicsNotifications,
  getInvestors,
  getPrivacyPolicy,
  getVideoById,
  getTestimonials,
  getBankOffer,
  getImageSliderFourW,
  getTestimonialsInfo,
  getBookaTestRideData,
  submitBookPilot,
  getStormEvFeatures,
  getBottomContent,
  getOurProduct,
  submitBookVehicle,
  submitDealerOnBoard,
  getCustomerData,
  getBottomContentHome,
  sendEnquireFormData,
  sendBrouchure,
};
